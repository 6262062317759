// Libraries
import dayjs from "dayjs";
import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import {
  createNewsBreadCrumb,
  generateNewsBreadcrumb,
  returnPostDateSegment,
} from "~/mixins/mixins";
import { BreadCrumb } from "~/types/BreadCrumb";
import { decodeUrlForPlace } from "~/operations/shared";

@Module({
  name: "breadcrumb",
  namespaced: true,
  stateFactory: true,
})
export default class BreadCrumbStore extends VuexModule {
  breadCrumb: BreadCrumb[] = [];
  breadCrumbNews: BreadCrumb[] = [];

  @Mutation
  setBreadCrumbForBundeslandAndCounty(breadCrumbData: BreadCrumb[]): void {
    breadCrumbData[breadCrumbData.length - 1].lastItem = true;
    this.breadCrumb = breadCrumbData;
  }

  @Mutation
  setBreadCrumbForCitySite(locationInfo: {
    city: string;
    state: string;
    county: string;
    sameCity: boolean;
    zipcode: string;
  }) {
    const { city, state, county, sameCity, zipcode } = locationInfo;
    if (sameCity) return;
    const cityBaseUrl = "/heizoelpreise";
    const isCityAndStateSame = city === state;
    const breadcrumbs = [];

    if (!isCityAndStateSame) {
      breadcrumbs.push({
        name: state,
        linkUrl: `${cityBaseUrl}/bundeslaender/${encodeURIComponent(
          state.toLowerCase(),
        )}`,
        linkValue: `${cityBaseUrl}/bundeslaender/${encodeURIComponent(
          state.toLowerCase(),
        )}`,
      });
    }
    if (county !== city) {
      breadcrumbs.push({
        name: county,
        linkUrl: `${cityBaseUrl}/bundeslaender/${encodeURIComponent(
          state.toLowerCase(),
        )}/${encodeURIComponent(county.toLowerCase())}`,
        linkValue: `${cityBaseUrl}/bundeslaender/${encodeURIComponent(
          state.toLowerCase(),
        )}/${encodeURIComponent(county.toLowerCase())}`,
      });
    }

    breadcrumbs.push({
      name: city,
      zipcode,
      linkUrl: decodeUrlForPlace(`heizoelpreise-${city}-${zipcode}`),
      linkValue: decodeUrlForPlace(`heizoelpreise-${city}-${zipcode}`),
      lastItem: true,
    });
    this.breadCrumb = breadcrumbs;
  }

  @Mutation
  setGlobalNewsBreadcrumbs(newsInfo) {
    const { path, query, fullPath } = newsInfo;
    const pathParts = path.split("/").filter((part) => part.trim() !== "");
    const breadcrumbs = pathParts
      .map((part, index) => generateNewsBreadcrumb(part, index, pathParts))
      .filter((breadcrumb) => breadcrumb !== null);

    if (query) {
      const month = returnPostDateSegment("month", query.startIsoDate);
      const year = returnPostDateSegment("year", query.startIsoDate);

      const breadCrumb: BreadCrumb = {
        linkUrl: fullPath,
        linkValue: fullPath,
        name: `News-Archiv ${month} ${year}`,
      };

      breadcrumbs.push(breadCrumb);
    }
    this.breadCrumbNews = breadcrumbs;
  }

  @Mutation
  setNewsBreadCrumbForIndividualBlogPost(newsInfo) {
    const { blogPost, routePath } = newsInfo;

    const formatDate = (date: any) => new Date(date).toISOString();
    const inputDate = new Date(blogPost.date);
    const startOfIsoDateString = formatDate(dayjs(inputDate).startOf("month"));
    const endOfIsoDateString = formatDate(dayjs(inputDate).endOf("month"));

    const dateSegments = [
      { linkUrl: `/news`, linkValue: `/news`, name: `News` },
      {
        linkUrl: `/news/archiv`,
        linkValue: `/news/archiv`,
        name: `News-Archiv`,
      },
      {
        linkUrl: `/news/archiv/archive-category?startIsoDate=${startOfIsoDateString}&endIsoDate=${endOfIsoDateString}`,
        linkValue: `/news/archiv/archive-category?startIsoDate=${startOfIsoDateString}&endIsoDate=${endOfIsoDateString}`,
        name: `News-Archiv ${returnPostDateSegment(
          "month",
          startOfIsoDateString,
        )} ${returnPostDateSegment("year", startOfIsoDateString)}`,
      },
      {
        linkUrl: routePath,
        linkValue: routePath,
        name: blogPost.title.rendered,
      },
    ];

    const breadCrumbNews = dateSegments.map((segment) =>
      createNewsBreadCrumb(segment.linkUrl, segment.linkValue, segment.name),
    );

    this.breadCrumbNews = breadCrumbNews;
  }
}
