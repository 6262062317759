// Libraries
import { Configuration, GeographyApi } from "@esyoil-gmbh/geography-client";
import axios from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { returnDateOnIntersections } from "../operations/store-helper-functions";
dayjs.extend(utc);
dayjs.extend(duration);

const config = new Configuration({
  basePath: process.env.BACKBONE_ESYOIL,
});
const geoApi = new GeographyApi(config);

@Module({
  name: "oilprice",
  namespaced: true,
  stateFactory: true,
})
export default class Oilprice extends VuexModule {
  IndexedCities: Array<string> = [
    "Hamburg",
    "Düsseldorf",
    "München",
    "Rostock",
    "Hannover",
    "Berlin",
    "Leipzig",
    "Dresden",
    "Frankfurt",
    "Karlsruhe",
    "Stuttgart",
  ];

  assets = {
    reletadUnits: "",
    labels: [],
    data: [],
  };

  historyChartData = {
    labels: [],
    data: [],
  };

  countyLinks = [];
  cityLinks = [];
  topStateCities = [];
  stockMarketData = [];
  districtPrices = [];

  cityPlaces = [];

  cityHistoryChartData = {
    labels: [],
    data: [],
  };

  stateAssetsPrice = {
    name: "",
    currentPrice: {
      value: 0,
      changedAtUnix: "",
    },
    lastPrice: {
      value: 0,
      changedAtUnix: "",
    },
    relativeChange: 0,
  };

  statesOilPriceHistory = {
    reletadUnits: "€ / 100 L",
    labels: [],
    sateOilPriceData: [],
    nationalOiPriceData: [],
  };

  stateArticle = {
    imageUrl: "",
    intro: [],
    content: [],
  };

  averageCityPrices = {
    dates: {
      actual: null,
      previous: null,
    },
    priceData: [],
  };

  @Mutation
  clearAssets() {
    this.assets.reletadUnits = "";
    this.assets.data = [];
  }

  @Mutation
  pushStockMarketData(assetsData) {
    // this.stockMarketData = [];
    this.stockMarketData = assetsData;
  }

  @Mutation
  setCityPlacesData(assetsData) {
    this.cityPlaces = assetsData;
  }
  // Restructure city historical price data from the current and previous five months
  @Mutation
  restructureCityHistoryChartData(assetsData) {
    // Property IDs to extract data for
    const propertyIds = [1000, 4000, 5000, 8000, 10000];

    const extractedData = [];
    const uniqueDates = new Set<string>();

    const currentDate = dayjs();
    const sixMonthsAgo = currentDate.subtract(5, "months");

    propertyIds.forEach((propertyId) => {
      if (assetsData[propertyId]) {
        const filteredData = assetsData[propertyId].filter((dataPoint) => {
          // eslint-disable-next-line no-prototype-builtins
          if (dataPoint.hasOwnProperty("x")) {
            const dataPointDate = dayjs.unix(dataPoint.x).utc();
            return (
              dataPointDate.isAfter(sixMonthsAgo.startOf("month")) &&
              dataPointDate.isBefore(currentDate.endOf("month"))
            );
          }
          return false;
        });

        extractedData.push(filteredData);

        filteredData.forEach((dataPoint) => {
          uniqueDates.add(
            dayjs.unix(dataPoint.x).utc().add(5, "hour").format("DD.MM.YYYY"),
          );
        });
      } else {
        extractedData.push([]);
      }
    });

    const sortedDateLabels = Array.from(uniqueDates).sort((a, b) => {
      return dayjs(a, "DD.MM.YYYY").unix() - dayjs(b, "DD.MM.YYYY").unix();
    });

    this.cityHistoryChartData.data = extractedData;
    this.cityHistoryChartData.labels = sortedDateLabels;
  }

  @Mutation
  restructureAndPushAssetsData(assetsData) {
    this.assets.reletadUnits = `${assetsData.unit.currency} / ${assetsData.unit.currencyDenominator}`;

    const lastDataEntryDate = dayjs.unix(
      assetsData.series[assetsData.series.length - 1].date,
    );
    const lastPrognosisEntryDate = dayjs.unix(assetsData.trend.to.date);
    const additionalPrognosisDays = dayjs
      .duration(lastPrognosisEntryDate.diff(lastDataEntryDate))
      .asDays();
    const relatedTotalOfDays =
      assetsData.series.length + additionalPrognosisDays;
    const totalLoopLength = relatedTotalOfDays;

    for (let i = 0; i < totalLoopLength; i++) {
      if (assetsData.series.length > i) {
        const Element = {
          changedAtUnix: assetsData.series[i].date,
          changedAtUtc: dayjs
            .unix(assetsData.series[i].date)
            .utc()
            .format("DD.MM.YYYY"),
          priceData: assetsData.series[i].price,
          pricePrognosisDataTop: returnDateOnIntersections(
            assetsData.series[i].date,
            assetsData.trend.from.date,
            assetsData.trend.from.priceRange[0],
          ),
          pricePrognosisDataBottom: returnDateOnIntersections(
            assetsData.series[i].date,
            assetsData.trend.from.date,
            assetsData.trend.from.priceRange[1],
          ),
          pricePrognosisStartDate: assetsData.trend.from.date,
        };
        this.assets.data.push(Element);
      } else {
        const Element = {
          changedAtUnix: "",
          changedAtUtc: dayjs
            .unix(assetsData.series[assetsData.series.length - 1].date)
            .utc()
            .add(i + 1 - assetsData.series.length, "days")
            .format("DD.MM.YYYY"),
          priceData: null,
          pricePrognosisDataTop: returnDateOnIntersections(
            i + 1,
            totalLoopLength,
            assetsData.trend.to.priceRange[0],
          ),
          pricePrognosisDataBottom: returnDateOnIntersections(
            i + 1,
            totalLoopLength,
            assetsData.trend.to.priceRange[1],
          ),
        };
        this.assets.data.push(Element);
      }
    }

    // Fallback, falls der letzte Eintrag kein Preis für den Trendkanal hat
    if (!this.assets.data[this.assets.data.length - 1].pricePrognosisDataTop) {
      this.assets.data[this.assets.data.length - 1].pricePrognosisDataTop =
        assetsData.trend.to.priceRange[0];
    }
    if (
      !this.assets.data[this.assets.data.length - 1].pricePrognosisDataBottom
    ) {
      this.assets.data[this.assets.data.length - 1].pricePrognosisDataBottom =
        assetsData.trend.to.priceRange[1];
    }
  }

  @Mutation
  restructureAndPushAssetsDataWithPrognosis(assetsData: {
    data: [number];
    range: Array<number | string>;
    name: string;
  }) {
    const firstDataEntryDate = dayjs.unix(assetsData.data[0][0]);
    const lastDataEntryDate = dayjs.unix(
      assetsData.data[assetsData.data.length - 1][0],
    );
    const lastPrognosisEntryDate = dayjs.unix(assetsData.range[1][0]);
    const totalOfDays = dayjs
      .duration(lastPrognosisEntryDate.diff(firstDataEntryDate))
      .asDays();
    const additionalPrognosisDays = dayjs
      .duration(lastPrognosisEntryDate.diff(lastDataEntryDate))
      .asDays();
    let relatedTotalOfDays = assetsData.data.length + additionalPrognosisDays;

    if (assetsData.name === "60 Monate")
      relatedTotalOfDays = assetsData.data.length + additionalPrognosisDays - 3;
    if (assetsData.name === "120 Monate")
      relatedTotalOfDays = assetsData.data.length + additionalPrognosisDays - 3;

    const totalLoopLength = relatedTotalOfDays;

    this.assets.reletadUnits = "€ / 100 L";

    for (let i = 0; i < totalLoopLength; i++) {
      if (assetsData.data.length > i) {
        const Element = {
          changedAtUnix: assetsData.data[i][0],
          changedAtUtc: dayjs
            .unix(assetsData.data[i][0])
            .utc()
            .format("DD.MM.YYYY"),
          priceData: assetsData.data[i][1],
          pricePrognosisDataTop: returnDateOnIntersections(
            assetsData.data[i][0],
            assetsData.range[0][0],
            assetsData.range[0][1],
          ),
          pricePrognosisDataBottom: returnDateOnIntersections(
            assetsData.data[i][0],
            assetsData.range[0][0],
            assetsData.range[0][2],
          ),
        };
        this.assets.data.push(Element);
      } else {
        if (assetsData.name === "60 Monate") i += 6;
        if (assetsData.name === "120 Monate") i += 10;
        const Element = {
          changedAtUnix: "",
          changedAtUtc: dayjs
            .unix(assetsData.data[assetsData.data.length - 1][0])
            .utc()
            .add(i + 1 - assetsData.data.length, "days")
            .format("DD.MM.YYYY"),
          priceData: null,
          pricePrognosisDataTop: returnDateOnIntersections(
            i + 1,
            totalLoopLength,
            assetsData.range[1][1],
          ),
          pricePrognosisDataBottom: returnDateOnIntersections(
            i + 1,
            totalLoopLength,
            assetsData.range[1][2],
          ),
        };
        this.assets.data.push(Element);
      }
    }

    // Fallback, falls der letzte Eintrag kein Preis für den Trendkanal hat
    if (!this.assets.data[this.assets.data.length - 1].pricePrognosisDataTop) {
      this.assets.data[this.assets.data.length - 1].pricePrognosisDataTop =
        assetsData.range[1][1];
    }
    if (
      !this.assets.data[this.assets.data.length - 1].pricePrognosisDataBottom
    ) {
      this.assets.data[this.assets.data.length - 1].pricePrognosisDataBottom =
        assetsData.range[1][2];
    }
  }

  @Action
  async fetchAssetsData(payload: {
    monthsQuantity: number;
    assetName: string;
  }) {
    this.context.commit("clearAssets");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.SIDE_ASSETS_PRICES}/${payload.assetName}/${payload.monthsQuantity}`,
      );
      this.context.commit("restructureAndPushAssetsData", result.data.data);
    } catch (error) {
      //
    }
  }

  @Action
  async fetchOilAssetsPrognosis(monthsQuantity: number) {
    try {
      this.context.commit("clearAssets");
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.CHARTS_JAHRESUEBERSICHT}/${monthsQuantity}`,
      );
      this.context.commit(
        "restructureAndPushAssetsDataWithPrognosis",
        result.data.data,
      );
    } catch (error) {
      //
    }
  }

  @Action
  async fetchCityPlacesData(payload: { cityName: string; zipcode: string }) {
    this.context.commit("setCityPlacesData", []);
    try {
      const result =
        await geoApi.geographyV1GetPlacesInMunicipalityNameZipcodeLimitGet(
          payload.cityName,
          payload.zipcode,
          50,
        );
      this.context.commit("setCityPlacesData", result.data);
    } catch (error) {
      console.log(error);
    }
  }

  @Mutation
  setNationalPriceTrend(priceTrendData) {
    this.stateAssetsPrice.currentPrice.value = priceTrendData.priceToday;
    this.stateAssetsPrice.lastPrice.value = priceTrendData.pricePreviousDay;
    this.stateAssetsPrice.relativeChange = priceTrendData.tendency;
  }
  @Action
  async fetchNationalPriceTrend() {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.CALC_NATIONAL_AVERAGE}`,
      );
      this.context.commit("setNationalPriceTrend", result.data.data[0]);
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  setDistrictPrices(
    priceTrendData: Array<{
      currentPrice: {
        name: string;
        relativeChange: { modifiedAt: number; unit: string; value: number };
        lastPrice: { modifiedAt: number; unit: string; value: number };
      };
    }>,
  ) {
    this.districtPrices = priceTrendData;
  }

  @Action
  async fetchDistrictPriceTrend() {
    try {
      const result = await axios({
        method: "get",
        url: `${process.env.API_ESYOIL}${process.env.FEDERAL_STATE_AVERAGE_PRICE}`,
        headers: {
          accept: "*/*",
        },
      });

      this.context.commit("setDistrictPrices", result.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async fetchStockMarketData() {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.STOCK_MARKET_DATA}`,
      );
      this.context.commit("pushStockMarketData", result.data.data.priceData);
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  setAverageCityPrices(priceData) {
    this.averageCityPrices.priceData = [];
    this.averageCityPrices.dates.actual = priceData.dates.actual;
    this.averageCityPrices.dates.previous = priceData.dates.previous;
    this.IndexedCities.forEach((city) => {
      const CityPriceModel = {
        name: city,
        zipcode: priceData[city].zipcode,
        seoName: priceData[city].seoName,
        price: {
          actual: priceData[city][priceData.dates.actual],
          previous: priceData[city][priceData.dates.previous],
          maxPrice24Month: priceData[city].max,
          minPrice24Month: priceData[city].min,
          change:
            priceData[city][priceData.dates.actual] -
            priceData[city][priceData.dates.previous],
        },
      };
      this.averageCityPrices.priceData.push(CityPriceModel);
    });
  }
  @Action
  async fetchAverageCityPrices() {
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.CITY_PRICE_AVERAGE}`,
      );
      this.context.commit("setAverageCityPrices", result.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  setHeatingOilHistoryPrices(assetsData: Array<Array<number>>) {
    this.assets.data = [];
    this.assets.labels = [];
    this.assets.reletadUnits = "€ / 100 L";
    assetsData.forEach((intersection) => {
      this.assets.data.push({
        x: intersection[0],
        y: intersection[1],
      });
      this.assets.labels.push(dayjs(intersection[0]).format("DD.MM.YYYY"));
    });
  }
  @Action
  async fetchHeatingOilHistoryPrices(monthsQuantity: number) {
    this.context.commit("clearAssets");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.CHARTS_JAHRESUEBERSICHT}/${monthsQuantity}`,
      );
      this.context.commit("setHeatingOilHistoryPrices", result.data.data.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Mutation
  restructureAndPushAssetsHistoryData(assetsData) {
    this.historyChartData.labels = [];
    this.historyChartData.data = [];
    const Year0 = [];
    const Year1 = [];
    const Year2 = [];
    const Year3 = [];
    assetsData.year0.forEach((intersection) => {
      Year0.push({
        x: intersection[0],
        y: Number(intersection[1]),
      });
    });
    assetsData.year1.forEach((intersection) => {
      Year1.push({
        x: intersection[0],
        y: Number(intersection[1]),
      });
    });
    assetsData.year2.forEach((intersection) => {
      Year2.push({
        x: intersection[0],
        y: Number(intersection[1]),
      });
    });
    assetsData.year3.forEach((intersection) => {
      Year3.push({
        x: intersection[0],
        y: Number(intersection[1]),
      });
      this.historyChartData.labels.push(
        dayjs.unix(intersection[0]).utc().format("DD.MM.YYYY"),
      );
    });
    this.historyChartData.data = [Year0, Year1, Year2, Year3];
  }
  @Action
  async fetchHistoricalOilPrices() {
    this.context.commit("clearAssets");
    try {
      const result = await axios.get(
        `${process.env.API_ESYOIL}${process.env.YEARS_COMPARSION_CHART}`,
      );
      this.context.commit(
        "restructureAndPushAssetsHistoryData",
        result.data.data,
      );
    } catch (error) {
      //
    }
  }

  @Action
  async fetchCityHistoryChartData(zipcode: string) {
    try {
      const result = await axios.get(
        `${process.env.BACKBONE_ESYOIL}/heating-oil-calculator/v1/history/chart/amount/${zipcode}`,
      );
      this.context.commit("restructureCityHistoryChartData", result.data);
    } catch (error) {
      console.log("error", error);
    }
  }
}
